import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
import { venues } from "config";

import prvni_patro from "../mk_e.jpg";
import druhe_patro from "../mk_i.jpg";

const Meeting = () => {
  const venueItem = venues[1];
  return (
    <article className="w-full">
      <PageTitle>Zasedání CF</PageTitle>

      <Helmet>
        <title>Zasedání CF | {basics.appTitle}</title>
        <meta property="og:title" content="Workshopy" />
      </Helmet>

      <div className="content-block">
        <h2>Zasedání Celostátního fóra v Praze</h2>
        <p>
          V&nbsp;sobotu 9.&nbsp;listopadu 2024 bude od 9.00 probíhat Zasedání CF v prostorech Masarykovy koleje v Praze.<br /><br />
          Ve večerních hodinách na zasedání naváže <strong>Afterparty</strong> a další zajímavý program<br /><br />
        </p>
      </div>
{/*

      <Button
        className="text-lg btn--to-blue-300 mb-2"
        bodyClassName="whitespace-no-wrap"
        color="black"
        href="program#sobota 29. 4. 2023"
        icon="ico--chevron-right"
      >
        Pořad zasedání
      </Button>
      &nbsp;
      <Button
        className="text-lg btn--to-blue-300 mb-2"
        bodyClassName="whitespace-no-wrap"
        color="black"
        href="https://pi2.cz/cf2023podklady"
        icon="ico--chevron-right"
        target="_blank"
      >
        Podklady ke stažení
      </Button>
*/}

      <div className="content-block">
        <p>
          <img
            src={prvni_patro}
            alt="SONO"
            className="block w-128 mt-4 mb-4"
          />

        </p>
        <p>
          <img
            src={druhe_patro}
            alt="SONO"
            className="block w-128 mt-4 mb-4"
          />

        </p>
      </div>


      <div className="content-block p-2">
      </div>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
      </div>

    </article>
  );
};

export default Meeting;
