export const basics = {
  appTitle: process.env.REACT_APP_CF_TITLE || "CF 2024",
  appDesc:
    process.env.REACT_APP_CF_DESC ||
    "Oficiální stránka letošního ročníku zasedání Celostátního fóra České pirátské strany, 9. 11. 2024",
};

export const api = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || "https://cf2024.pirati.cz/api",
};

export const appurl = {
  baseUrl: process.env.REACT_APP_ROOT_URL || "https://cf2024.pirati.cz",
};


export const eventHall = {
  text: "Zasedání CF 2024",
  title_short: "Masarykova kolej Praha",
  startinfo: "Sobota 9. listopadu 9:00",
  endinfo: "Sobota 9. listopadu 18:00",
  title: "Masarykova kolej Praha",
  desc: "Zasedání celostátního fóra v sobotu 9. 11. 2024 od 9:00",
  coords: { lat: 50.1009044, lon: 14.3868344 },
  street: "Thákurova 1",
  city: "160 00 Praha 6 - Dejvice",
  gps: "50.1009044N, 14.3868344E",
  href: "https://www.suz.cvut.cz/cz/komercni-sluzby/organizace-kongresu",
  programlink: "program#sobota 9. 11. 2024",
  infolink: "zasedani",
  type: "event",
  group: "basics",
};

export const workshopVenue = {
  text: "Workshopy",
  title_short: "Café Práh",
  startinfo: "Neděle 14. ledna 09.30",
  endinfo: "Neděle 14. ledna 17.00",
  title: "Café Práh",
  desc: "Workshopy v neděli 14. 1. 2024 od 9.30",
  coords: { lat: 49.1872624, lon: 16.615547 },
  street: "Ve Vaňkovce 462/1",
  city: "60200 Brno - Trnitá",
  gps: "49.1872624N, 16.615547E",
  href: "https://www.cafeprah.cz/",
  programlink: "program#neděle 14. 1. 2024",
  infolink: "workshopy",
  type: "event",
  group: "basics",
};


export const partyVenue = {
  text: "Warm-Up Párty",
  startinfo: "Pátek 12. ledna 21:00",
  endinfo: "Sobota 13. ledna 06.00",
  title_short: "Na Střeláku",
  title: "Na Střeláku",
  desc: "Warm-Up párty v pátek 12. 1. 2024 od 21:00",
  coords: { lat: 49.1920890479743, lon: 16.564827167470355},
  street: "Pisárecká 7",
  city: "Brno (u zastávky MHD Antrophos)",
  gps: "49.1920890479743N, 16.564827167470355E",
  href: "https://www.nastrelaku.cz/contact",
  programlink: "program#pátek 12. 1. 2024",
  infolink: "party",
  type: "event",
  group: "basics",
};


export const partyVenue2 = {
  title_short: "LaFuente",
  street: "Národní obrany 935/13",
  city: "Praha 6 - Bubeneč",
  gps: "50.0995411N, 14.3998497E",
  href: "https://www.lafuente.cz/",
};

export const partyVenue3 = {
  title_short: "Masařka Club",
  street: "Thákurova 550/1",
  city: "Praha 6 - Bubeneč",
  gps: "50.1009044N, 14.3868344E",
  href: "https://www.facebook.com/masarka.club",
};

export const railwayStation = {
  title: "Vlakové nádraží",
  desc: "Brno hlavní nádraží",
  coords: { lat: 49.1906243, lon: 16.6128025 },
  type: "train",
  group: "transportation",
};

export const busStation = {
  title: "Autobusové nádraží",
  desc: "Brno, ÚAN Zvonařka",
  coords: { lat: 49.1865117, lon: 16.6190199 },
  type: "train",
  group: "transportation",
};

export const parkingVeveri = {
  title: "Parkoviště Veveří",
  desc: "Parkoviště Veveří nad Björnsonovým sadem s kapacitou 140 míst",
  coords: { lat: 49.2075499, lon:  16.5897507},
  type: "location",
  group: "transportation",
};

export const parkingSumavska = {
  title: "Parkoviště Šumavská",
  desc: "Parkoviště Šumavská i s podzemním parkovištěm u Lidlu",
  coords: { lat: 49.2102435, lon: 16.5911999},
  type: "location",
  group: "transportation",
};

export const parkingGymnasium = {
  title: "Parkoviště Gymnázium",
  desc: "Parkoviště Gymnázium Matyáše Lercha",
  coords: { lat: 49.2073543, lon: 16.5893270},
  type: "location",
  group: "transportation",
};

export const parkingVankovka = {
  title: "Parkoviště Galerie Vaňkovka",
  desc: "Parkování v Galerie Vaňkovka - v neděli je zdarma",
  coords: { lat: 49.1873866, lon: 16.614276},
  type: "location",
  group: "transportation",
};

export const busStopTabor = {
  title: "Zastávka Tábor",
  desc: "Zastávka Tábor",
  coords: { lat:  49.21047610015332, lon: 16.587657771543906},
  type: "train",
  group: "transportation",
};

export const busStopiStation = {
  title: "Zastávka Hlavní nádraží",
  desc: "Zastávka Hlavní nádraží",
  coords: { lat:  49.192206512392154, lon: 16.61391223863288 },
  type: "train",
  group: "transportation",
};

export const map = {
  center: { lat: 50.1009, lon: 14.3868, zoom: 14 },
  markers: [eventHall],
//  markers: [eventHall, partyVenue, workshopVenue, railwayStation, busStation, parkingVeveri, parkingSumavska, parkingGymnasium, parkingVankovka, busStopTabor, busStopiStation],
};

export const venues = [ partyVenue, eventHall, workshopVenue];

export const nav = [
  {
    title: "Zasedání CF",
    link: "/zasedani",
  },
//  {
//      link: "/registrace",
//      title: "Registrace",
//      notonhome: false,
//  },
  {
    link: "/ubytovani",
    title: "Ubytování",
  },
//  {
//    title: "Stravování",
//    link: "/stravovani",
//  },
//  {
//    link: "/doprava",
//    title: "Doprava",
//  },
//  {
//    link: "/program",
//    title: "Program",
//  },
  {
    title: "Party",
    link: "/party",
  },
//  {
//    title: "Workshopy",
//    link: "/workshopy",
//  },
// {
//   link: "/mapa",
//    title: "Mapa",
//  },
  {
    link: "/organizatori",
    title: "Organizátoři",
  },
//  {
//    link: "/volba-predsednictva",
//    title: "Volba předsednictva",
//  },
//  {
//    link: "/hendikepovani",
//    title: "CF bez bariér",
//  },
  {
    link: "/deti-a-zabava",
    title: "Děti a zábava",
  },
//  {
//      link: "/pro-dobrovolniky",
//      title: "Dobrovolníci",
//  },
];

export const organisers = [
  {
    title: "Organizační tým",
    members: [
      {
        name: "Jiří Belšán",
        position: "Hlavní organizátor",
//        phone: "608366554",
        email: "jiri.belsan@pirati.cz",
        avatarUrl: "/medailonky/jiri-belsan.jpg",
      },
      {
        name: "Hana černošová",
        position: "Koordinátorka dobrovolníků",
        phone: "770113645",
        email: "hana.cerbosova@pirati.cz",
        avatarUrl:
        "/medailonky/hana-cernosova.jpg",
      },
      {
        name: "Vojtěch Pikal",
        position: "Pořad zasedání",
        email: "vojtech.pikal@pirati.cz",
        phone: "778702714",
        avatarUrl:
          "/medailonky/vojtech-pikal.jpg",
      },
      {
        name: "Tomáš Pastirčák",
        position: "Pořad zasedání",
        email: "tomas.pastircak@pirati.cz",
        phone: "724692024",
        avatarUrl:
          "/medailonky/tomas-pastircak.jpg",
      },

      {
        name: "Josef Bouše",
        position: "Technické zajištění",
        phone: "+420775180566",
        email: "josef.bouse@pirati.cz",
        avatarUrl:
          "/medailonky/josef-bouse.jpg",
      },
      {
        name: "Lucie Švehlíková",
        position: "Styk s médii, tisková mluvčí",
        email: "luce.svehlikova@pirati.cz",
        phone: "605263660",
        avatarUrl:
          "/medailonky/lucie-svehlikova.png",
      },

      {
        name: "Linda Kašpárková",
        position: "Koordinátorka činovníků, Stage manager",
        email: "linda.kasparkova@pirati.cz",
        phone: "608360206",
        avatarUrl:
          "https://lide.pirati.cz/media/cache/ef/ad/efad8f93796f4d3b9ee2b33d0f369c12.jpg",
      },
      {
        name: "Andrej Ramašeuski",
        position: "Registrace, web",
        phone: "605234197",
        email: "andrej.ramaseuski@pirati.cz",
        avatarUrl:
          "/medailonky/andrej-ramaseuski.jpg",
      },
/*
      {
        name: "Jiří Kárský",
        position: "Finance, hospodář",
        email: "jiri.karsky@pirati.cz",
        phone: "+420735586424",
        avatarUrl:
          "/medailonky/unknown-pirate.jpg",
      },
*/
    ],
  },
];

export const accommodation = [
  {
    title: "Hotel Masarykova kolej",
    link: "https://www.masarykovakolej.cz/hotel",
    email: "rezervace-mk@cvut.cz",
    desc: "Hotel přímo v místě konání CF. Snídaně v ceně pokoje.",
    coords: { lat: 50.1009044, lon: 14.3868344 },
    distance: 0,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1250,
      },
      {
        bedCount: 2,
        price: 1750,
      },
    ],
  },

  {
    title: "Hotel Meda",
    link: "https://hotelmeda.cz/cs.html",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 50, lon: 14 },
    distance: 1000,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 2000,
      },
    ],
  },

  {
    title: "Hostel Dakura",
    link: "https://hosteldakura.com",
    email: "",
    desc: "Bez snídaně",
    coords: { lat: 50, lon: 14 },
    distance: 1200,
    priceClass: "$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 900,
      },
      {
        bedCount: 2,
        price: 1300,
      },
    ],
  },

  {
    title: "Hotel DAP",
    link: "https://www.volareza.cz/hotel-dap/",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 50, lon: 14 },
    distance: 700,
    priceClass: "$$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1800,
      },
      {
        bedCount: 2,
        price: 2000,
      },
    ],
  },

  {
    title: "Hotel International",
    link: "https://www.hotelint.cz/",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 50, lon: 14 },
    distance: 1200,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 2500,
      },
      {
        bedCount: 2,
        price: 2500,
      },
    ],
  },
  {
    title: "Hotel Mama Shelter",
    link: "https://mamashelter.com/prague/",
    email: "",
    desc: "Snídaně v ceně pokoje.",
    coords: { lat: 50, lon: 14 },
    distance: 3700,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2160,
      },
    ],
  },
];

export const programGeneralSettings = {
  published: true,
};

export const program = {
  negotiation: {
    isUnderway: true,
    forumLink: "https://forum.pirati.cz/viewtopic.php?p=836020#p836020",
  },
  published: true,
  days: [
    {
      title: "pátek 12. 1. 2024",
      hourStart: 19,
      hourEnd: 24,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 15,
      tracks: [
        {
          code: "preparty",
          name: "Warm-up",
          venue: "Na Střeláku (Pisárecká 7)",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "preparty-punk",
          name: "Punkový syndrom",
          venue: "ŠPiCe (3. patro, Lidická 81)",
          colorBg: "black",
          colorFg: "white",
        },
        {
          code: "preparty-games",
          name: "Deskovky",
          venue: "Rishada (Antonína Slavíka 2)",
          colorBg: "cyan-400",
          colorFg: "white",
        },
      ],
      slots: [
        {
          time: "19:00",
          sessions: [
            {
              track: "preparty",
              timeStart: "19:00",
              timeEnd: "23:59",
              title: "Tancuj, tancuj, vykrúcaj!",
              agenda: [
                "19:00 Otevření",
                "20:00 Volné pódium pro písničkáře s kytarou, harmonikou a dalšími nástroji (sólo)",
                "21:00 Pirátští DJs"
              ],
            },
          ],
        },
        {
          time: "19:00",
          sessions: [
            {
              track: "preparty-punk",
              timeStart: "19:00",
              timeEnd: "21:00",
              title: "Punkový syndrom",
              agenda: [
                "Tuto možnost využije každý, kdo chce vidět vtipný a zároveň hluboce lidský film o obyčejných problémech neobyčejných lidí a pak diskutovat o životě lidí s hendikepem. Připravili jsme projekci dokumentárního filmu 'Punkový syndrom' o punkové kapele tvořené muzikanty s mentálním hendikepem. Po filmu bude následovat uvolněná debata o sociálních službách a životu s hendikepem v ČR.",
              ],
            },
          ],
        },
        {
          time: "19:00",
          sessions: [
            {
              track: "preparty-games",
              timeStart: "19:00",
              timeEnd: "23:00",
              title: "Kdo si hraje, netrollí",
              agenda: [
                "Pokud máte rádi deskovky a chcete strávit večer s podobně postiženými jedinci, určitě doražte do deskoherny Rishada.",
              ],
            },
          ],
        },
      ],
    },
    {
      title: "sobota 13. 1. 2024",
      hourStart: 7,
      hourEnd: 24,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "main",
          name: "Hlavní program",
          venue: "Sono Centrum",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "voting",
          name: "Hlasování",
          venue: "Helios",
          colorBg: "black",
          colorFg: "white",
        },
        {
          code: "alternative",
          name: "Alternativní program",
          venue: "Hlavní program",
          colorBg: "grey-125",
          colorFg: "black",
        },
      ],
      slots: [
        {
          time: "09:00",
          sessions: [
            {
              track: "main",
              timeStart: "07:30",
              timeEnd: "09:00",
              title: "Otevření sálu",
              agenda: [
"Otevření sálu",
"Zahájení registrace",
              ],
            },
            {
              track: "main",
              timeStart: "09:00",
              timeEnd: "12:30",
              title: "Dopolední blok",
              agenda: [
"Zahájení",
"Hosté Republikového předsednictva",
"Volba předsedy",
"Volba místopředsedů – představení kandidátů a rozprava před 1. kolem",
"Senátní výsledky a kandidáti",
"Prezentace Republikového výboru",
"Plnění usnesení Celostátního fóra",
              ],
            },
          ],
        },
        {
          time: "08:00",
          sessions: [
            {
              track: "alternative",
              timeStart: "08:00",
              timeEnd: "18:30",
              title: "Presscentrum - Vyhrazeno pro novináře",
              agenda: [
                "9.00 - 18.30	Prostor vyhrazen novinářům a tiskovému centru",
              ],
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "main",
              timeStart: "13:00",
              timeEnd: "14:00",
              title: "Společné foto a Obědová pauza",
              agenda: [
"Podle situace mezi 12:30 a 14:30 proběhne obědová pauza na max 1, hodiny",
"Před pauzou proběhne v hlavním sále společné focení"
              ],
            },
          ],
        },
        {
          time: "14:30",
          sessions: [
            {
              track: "main",
              timeStart: "14:30",
              timeEnd: "19:00",
              title: "Odpolední blok",
              agenda: [
"Volba místopředsedů – rozpravy a výsledky voleb",
"Představení krajů",
"Výsledky práce ve vládě a poslanecké sněmovně",
"Prezentace Etického Kodexu a práce Ombudsmanky",
"Posun v pilotu resortních týmů",
"Výroční zpráva předsedy",
"Výsledky za 5 let v Evropském parlamentu",
"Ukončení",
              ],
            },
          ],
        },
        {
          time: "19:00",
          sessions: [
            {
              track: "main",
              timeStart: "20:00",
              timeEnd: "01:00",
              title: "Afterparty",
              agenda: [
"20:30 - 21:30	Where Are We Now (funk / indie)",
"22:00 - 23:00	Tezaura (melodic metal)",
"23:30 - 01:00	MΛЯRΛ (house / electroswing)",
              ],
            },

            {
              track: "alternative",
              timeStart: "19:30",
              timeEnd: "21:00",
              title: "Jak na konstruktivní zpětnou vazbu - Diskuze s koordinátorkou prevence, vrbami a ombudsmankou",
              agenda: [
                "Schopnost poskytovat konstruktivní zpětnou vazbu patří mezi důležité dovednosti pro spolupráci v týmu. Zároveň se jedná o častou výzvu, které je užitečné věnovat pozornost. Pojďme diskutovat o tom, jak zpětnou vazbu dávat i přijímat. Během workshopu budeme společně rozebírat příklady dobré praxe, užitečné nástroje a techniky a vyzkoušíme si interaktivní cvičení v bezpečném postředí",
              ],
            },
          ],
        },

      {
          time: "09:30",
          sessions: [
            {
              track: "voting",
              timeStart: "10:15",
              timeEnd: "11:20",
              title: "Volba předsedy – 1. kolo",
              agenda: [
                "Hlasování - cca 10:15 až 11:15 (1 hodina)",
                "Vyhlášení výsledku - cca 11:20",
              ],
            },
            {
              track: "voting",
              timeStart: "11:45",
              timeEnd: "12:50",
              title: "Volba předsedy – 2. kolo",
              agenda: [
                "Hlasování - cca 11:45 až 12:45 (1 hodina)",
                "Vyhlášení výsledku - cca 12:50",
              ],
            },
            {
              track: "voting",
              timeStart: "12:55",
              timeEnd: "14:30",
              title: "Volba místopředsedů – 1. kolo",
              agenda: [
                "Hlasování - cca 13:00 až 14:00 (1 hodina)",
                "Vyhlášení výsledku - cca 14:30",
              ],
            },
            {
              track: "voting",
              timeStart: "15:30",
              timeEnd: "16:35",
              title: "Volba místopředsedů – 2. kolo",
              agenda: [
                "Hlasování - cca 15:30 až 16:30 (1 hodina)",
                "Vyhlášení výsledku - cca 16:35",
              ],
            },
            {
              track: "voting",
              timeStart: "17:00",
              timeEnd: "18:15",
              title: "Volba místopředsedů – 3. kolo",
              agenda: [
                "Hlasování - cca 17:00 až 18:00 (1 hodina)",
                "Vyhlášení výsledku - cca 18:15",
              ],
            },
          ],
        },

      ],
    },

    {
      title: "neděle 14. 1. 2024",
      hourStart: 9,
      hourEnd: 14,
    // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "workshopy1",
          name: "Workshopy (Sál 1)",
          venue: "Café Práh",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "workshopy2",
          name: "Workshopy (Sál 2)",
          venue: "Café Práh",
          colorBg: "cyan-300",
          colorFg: "white",
        },
        {
          code: "workshopy3",
          name: "Diskuze",
          venue: "ŠPiCe, Lidická 81",
          colorBg: "grey-125",
          colorFg: "black",
        },
      ],
      slots: [
        {
          time: "09:00",
          sessions: [
            {
              track: "workshopy1",
              timeStart: "09:00",
              timeEnd: "09:50",
              title: "Úvod ke kampani do EP",
              agenda: [
                "Pavel Štěpánek",
                "Pavel Štěpánek představí základní strategii, prozradí claim a poví o tom, co nás v kampani čeká",
              ],
            },
          ],
        },
        {
          time: "10:00",
          sessions: [
            {
              track: "workshopy1",
              timeStart: "10:00",
              timeEnd: "10:50",
              title: "Co ohrožuje naši bezpečnost",
              agenda: [
                "Markéta Gregorová",
              ],
            },
          ],
        },
        {
          time: "11:00",
          sessions: [
            {
              track: "workshopy1",
              timeStart: "11:00",
              timeEnd: "11:50",
              title: "Radikalizace a my. Jdeme slovenskou cestou?",
              agenda: [
                "Olga Richterová",
                "Jako Piráti jsme zažili sílu dezinfo kampaně v r. 2021. Na Slovensku na opozici a osobnosti nezisku na ulici i plivou. Olga se o lež úspěšně soudila, na erozi maďarské demokracie ale nastíní, jak slabou satisfakcí jsou i vyhrané soudy. Expertka A. Michalcová pak pokryje i vliv AI a výzkumy ukazující, že česká společnost Slovensko následuje. A co s tím dál? Více v debatě.",
              ],
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "workshopy1",
              timeStart: "12:00",
              timeEnd: "12:50",
              title: "Démon na dně sklenky",
              agenda: [
                "Adéla Šípová, Janka Michailidu, Petr Konečný",
                "Odhalíme temnou tvář alkoholu, propojujíce návykové chování, duševní zdraví a násilí. Společně si odpovíme na otázky: Jak alkohol formuje a ničí životy? Jak negativům účinně předcházet?",
              ],
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "workshopy1",
              timeStart: "13:00",
              timeEnd: "13:50",
              title: "Pirátské životní prostředí a Evropa",
              agenda: [
                "Klára Kocmanová, David František Wagner",
                "Cílem workshopu je poskytnout účastníkům vhled do evropských klimatických politik. Zaměříme se na základní principy, které jsou v EU za účelem ochrany životního prostředí a klimatu uplatňovány a seznámíme se s konkrétními opatřeními, které z nich vycházejí. Průřezově tak probereme záměr, legislativu i šťastné a nešťastné skutečné dopady. Hodně času vymezujeme na diskuzi, ale chceme zajistit aby všichni návštěvníci odešli s jasnou představou co svět dělá a proč.",
              ],
            },
          ],
        },
        {
          time: "10:00",
          sessions: [
            {
              track: "workshopy2",
              timeStart: "10:00",
              timeEnd: "10:50",
              title: "Sociální vyloučení a mýtus rovnostářského Česka",
              agenda: [
                "Zuzana Klusová",
                "Chudoba, nerovnost a nespravedlnost jsou živnou půdou pro růst extrémismu a napětí ve společnost. My Piráti si to uvědomujeme, místo represe a přísnosti na chudé, ale chceme jít cestou vzdělávání, dostupného bydlení a funkční sítě sociálních služeb. Jaké konkrétní změny chystáme se dozvíš, když přijdeš!",
              ],
            },
          ],
        },
        {
          time: "11:00",
          sessions: [
            {
              track: "workshopy2",
              timeStart: "11:00",
              timeEnd: "11:50",
              title: "Moderní Evropa",
              agenda: [
                "Marcel Kolaja",
                "Moderní technologie, které pomáhají. Spravedlivý daňový systém. Nebo vzdělávání, které připraví děti na budoucnost. To vše jsou klíčové aspekty pro moderní Evropu. Jak tam – s Piráty za kormidlem – dojít? Poslanec a kvestor Evropského parlamentu Marcel Kolaja představí pirátskou vizi moderní Evropy.",
              ],
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "workshopy2",
              timeStart: "12:00",
              timeEnd: "12:50",
              title: "Euro, otázka především hodnotová",
              agenda: [
                "Zuzana Freitas, Jiří Valenta",
                "Euro, jako společná měna existuje v prostoru Evropy bezmála 25 let. Euro vzniklo v roce 1999. Euro-bankovky a mince se objevily za nedlouho. Nyní je euro měnou s druhým největším objemem obchodní výměny na světě. Workshop na CFku pojmeme jako  debatu nad tím, za jakých podmínek má Česká republika euro přijmout.Právě nyní je ten správný čas v pirátské straně vyjasnit mantinely a vyčit jasnou trasu, kudy do eurozóny.",
              ],
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "workshopy2",
              timeStart: "13:00",
              timeEnd: "13:50",
              title: "Korupce v Evropě. Jak ohlídat zloděje?",
              agenda: [
                "Mikuláš Peksa",
                "V 7 z 10 Evropanů panuje pocit, že v jejich zemi převládá korupce. Zatímco Piráti jsou jedinou stranou v ČR bez korupce, oligarchové a vlády v Evropě si neoprávněně plní kapsy veřejnými financemi. Mikuláš Peksa ze svých zkušeností přiblíží fungování distribuce evropského rozpočtu a vysvětlí způsoby, jak bránit jeho zneužití. Na příkladě Maďarska pak představí, proč je v boji proti korupci zásadní hlídat fungování demokratických institucí a právního státu.",
              ],
            },
          ],
        },
        {
          time: "10:20",
          sessions: [
            {
              track: "workshopy3",
              timeStart: "10:30",
              timeEnd: "11:30",
              title: "ŠPiCe, Lidická 81 - Diskuze o návrhu na reformu aparátu strany",
              agenda: [
                "David františek Wagner a Tomáš Válek",
                "Diskuze o návrhu na reformu aparátu strany Pokud tě zajímá, co je to reforma aparátu strany, přijď se podívat, jak to dnes funguje a co navrhujeme.",
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const partyBureauCandidates = [
  {
    postTitle: "Předsednický post",
    candidates: [
      {
        name: "Ivan Bartoš",
        desc: "Ivan Bartoš (20. 3. 1980) je dlouholetým předsedou Pirátů. Stál už u vzniku strany v roce 2009. Od roku 2017 je poslancem PSP ČR, v současné vládě zformované na konci roku 2021 se stal vicepremiérem pro digitalizaci a ministrem pro místní rozvoj. Jako poslanec se již v minulém volebním období zasadil o vznik legislativy, která občanům garantuje právo na digitální službu. V tomto období se postaral o vznik Digitální informační agentury a pokračující digitalizaci ve formě eDokladů, digitální plné moci a dalších projektů. Jeho prioritou je dále plnění pirátského programu v oblasti lidských práv a na ministerstvu pro místní rozvoj problematika dostupnosti bydlení.",
        link: "https://forum.pirati.cz/viewtopic.php?p=860589#p860589",
        img: "ivan-bartos.jpg",
      },
      {
        name: "Markéta Gregorová",
        desc: "Markéta Gregorová (14. 1. 1993) byla v letech 2018 a 2019 předsedkyní Evropské pirátské strany, nyní je vedoucí resortního týmu Evropa, zahraničí, obranaa členkou Republikového výboru. Od května 2019 je poslankyní Evropského parlamentu. Zde je členkou výboru pro Mezinárodní obchod a členkou a koordinátorkou podvýboru pro Bezpečnost a obranu. Působí také ve výborech pro Zahraniční věci a Ústavní záležitosti. Je místopředsedkyní delegace Euronestu a skupiny přátel Ujgurů. Věnuje se omezení vývozu zbraní a sledovacích technologií, dezinformacím, kyberbezpečnosti i dalším moderním technologiím v oblasti bezpečnosti, ale také lidským právům pod útlakem autoritářů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861482#p861482",
        img: "marketa-gregorova.jpg",
      },
    ],
  },
  {
    postTitle: "Místopředsednický post",
    candidates: [
      {
        name: "Marcel Kolaja",
        desc: "Marcel Kolaja (29. 6. 1980) je od roku 2022 1. místopředsedou Pirátů. V roce 2019 byl zvolen poslancem Evropského parlamentu, kde do roku 2022 zastával funkci místopředsedy a nyní kvestora. Působí také ve výboru pro vnitřní trh a ochranu spotřebitelů (IMCO) a ve výboru pro kulturu a vzdělávání (CULT). Věnuje se zejména tématům spojeným s fungováním společnosti v digitálním věku. Prosazuje otevřené technologie, svobodu na Internetu, nezávislost médií, transparentnost a sjednocenou Evropu.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861343#p861343",
        img: "marcel-kolaja.jpg",
      },
      {
        name: "Jana Holomčík Leitnerová",
        desc: "ana Holomčík Leitnerová (13. 6. 1987) je členkou Rady Jihomoravského kraje s gescí sociální a rodinná politika. Už během studií začala působit v PR a marketingu, po studiích se věnovala konzultacím a tvorbě obsahu a PR a marketingových strategií pro firmy, NNO i jednotlivce. Spolupracovala také s Úřadem vlády ČR v oblasti hate speech a online informační gramotnosti. Dlouhodobě se věnuje tématům rovnoprávnosti, lidským právům a informační gramotnosti. Jakožto radní se posledních několik let věnuje udržitelnému a efektivnímu rozvoji sociálních služeb. Dále také tématům péče o duševní zdraví a paliativní péči či preventivním službám. Zabývá se také fenoménem sendvičové generace.",
        link: "https://forum.pirati.cz/viewtopic.php?p=859932#p859932",
        img: "jana-lejtnerova.jpg",
      },
      {
        name: "Hana Hajnová",
        desc: "Hana Hajnová (18. července 1985) je profesí projektová manažerka a od roku 2018 je zastupitelkou v Telči. V roce 2020 se stala lídryní kandidátky do krajských voleb, ve kterých Piráti skončili druzí, a z Hanky se stala první náměstkyně hejtmana pro oblast regionálního rozvoje. Od ledna 2022 je také druhou místopředsedkyní Pirátů. Z pozice krajské náměstkyně otevírá nové příležitosti rozvoje Vysočiny, ať už jde o zlepšení nabídky pracovních míst s vyšší přidanou hodnotou, nastartování udržitelné energetiky nebo efektivní cílení krajských a evropských dotací. Snaží se také do veřejného prostoru dostat více žen například pomocí projektu Ženy Vysočiny.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861159#p861159",
        img: "hana-hajnova.jpg",
      },
      {
        name: "Albert Štěrba",
        desc: "Albert Štěrba (18. února 1994) pracuje jako neurochirurg na klinice ve Fakultní nemocnici Královské Vinohrady v Praze. Dále se věnuje výzkumu a výuce na 3. lékařské fakultě Univerzity Karlovy. V roli zástupce předsedy spolku Mladí lékaři se zajímá o zdravotnictví. Je rovněž příslušník aktivních záloh Armády ČR. V politice jsou pro něj prioritní témata jako kvalita a dostupnost zdravotní péče, prevence civilizačních chorob, zajištění dostatku léků a fungování sociálních služeb, ochrana životního prostředí a přechod k udržitelné ekonomice; jako důležité vnímá v současné době i obranu a bezpečnost.",
        link: "https://forum.pirati.cz/viewtopic.php?p=860829#p860829",
        img: "albert-sterba.jpg",
      },
      {
        name: "Dominika Poživilová Michailidu",
        desc: "Dominika Poživilová Michailidu (20. 8. 1980) se stala členkou Pirátů v roce 2012 poté, co se zúčastnila několika demonstrací proti ACTA. Byla lídryní v krajských volbách pro Středočeský kraj a následně i ve volbách do Poslanecké sněmovny. V roce 2016 byla zvolena do Republikového předsednictva Pirátské strany. V současnosti je zastupitelkou v obci Krnsko, vedoucí krajského expertního týmu pro zemědělství a životní prostředí, místopředsedkyně pirátského místního sdružení Mladá Boleslav a členka Republikového výboru. Účastní se resortních týmů Zemědělství a Životní prostředí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861106#p861106",
        img: "dominika-michailidu.jpg",
      },
      {
        name: "Jana Skopalíková",
        desc: "Jana Skopalíková (27. 2. 1993) je 1. místopředsedkyně středočeského krajského sdružení. Angažuje se v krajském mediálním odboru a krajském expertním týmu Životní prostředí a zemědělství. Členkou Pirátů se stala v polovině roku 2018, v roce 2020 byla zvolena do středočeského zastupitelstva a stala se radní pro životní prostředí a zemědělství. V roce 2023 byla zvolena do Republikového výboru Pirátů. Zabývá se tématy životního prostředí a zemědělství – udržitelností, snižováním dopadů klimatické změny nebo cirkulární ekonomikou. Mimo to ji zajímá i sociální oblast, duševní zdraví nebo téma veřejné dopravy",
        link: "https://forum.pirati.cz/viewtopic.php?p=864484#p864484",
        img: "jana-skopalikova.jpg",
      },
      {
        name: "Mikuláš Peksa",
        desc: "Mikuláš Peksa (18. 6. 1986) je členem české a německé Pirátské strany. Působil v předsednictvu českých Pirátů a v českém parlamentu. Vystudoval fyziku na MFF UK. Od roku 2019 je europoslancem a předsedou Evropské pirátské strany. V Evropském parlamentu se věnuje dotačním politikám, ochraně právního státu, digitalizaci a modernizaci ekonomiky s ohledem na udržitelnost a ochranu klimatu. Je členem parlamentních výborů pro průmysl, výzkum a energetiku, dále Hospodářského a měnového výboru, Výboru pro rozpočtovou kontrolu, Zvláštního výboru pro pandemii onemocnění COVID-19 a také podvýboru pro daňové záležitosti.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861002#p861002",
        img: "mikulas-peksa.jpg",
      },
      {
        name: "Tereza Vovesová",
        desc: "Tereza Vovesová (31. 1. 1981) pracovala ve veřejné správě na komunální úrovni. Podílela se na přípravě Strategie rozvoje nebo na zavedení systému CAF pro veřejný sektor. Dlouhodobě se ve farmacii věnuje realizaci mezinárodních projektů v rámci České republiky. Po vstupu Pirátů do komunální politiky se podílela na pořádání prvního Fóra komunálních zastupitelů. V letech 2021–2023 byla členkou Republikového výboru. Zajímá se o podporu informovanosti a participace veřejnosti. Jako důležitou součást efektivního a udržitelného vedení samospráv vidí snahu o zapojení občanů do diskuze při rozvoji a plánování budoucího vývoje měst a obcí. Daný přístup společně s dalšími faktory, například rozvoj veřejného prostranství, vnímá jako důležité složky pro zdravý rozvoj občanské společnosti a demokracie.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861656#p861656",
        img: "tereza-vovesova.jpg",
      },
      {
        name: "Klára Kocmanová",
        desc: "Klára Kocmanová (5. 11. 1992) je od roku 2021 poslankyní PSP ČR. Je také místopředsedkyní poslaneckého klubu Pirátů a Výboru pro životní prostředí a předsedkyní Stálé komise pro kontrolu odposlechu. Dále je členkou Výboru pro sociální politiku a Podvýboru pro problematiku domácího a sexuálního násilí. Dříve byla místopředsedkyní středočeských Pirátů a předsedkyní místního sdružení v Kutné Hoře, kde pracovala/se angažovala v komisích rady města. Dlouhodobě se věnuje lidským právům, sociálním tématům a životnímu prostředí. Ve Sněmovně pracuje na legislativě týkající se sexualizovaného násilí, zrovnoprávnění LGBTQ+ lidí, životního prostředí a práv zvířat.",
        link: "https://forum.pirati.cz/viewtopic.php?p=861658#p861658",
        img: "klara-kocmanova.jpg",
      },
      {
        name: "Lukáš Hejduk",
        desc: "Lukáš Hejduk (2. 4. 1994) působí od roku 2022 v kontrolní komisi Pirátů, je členem krajského sdružení Jihomoravský kraj a v době mezi listopadem 2016 a srpnem 2017 byl místopředsedou krajského sdružení Vysočina. Dlouhodobě se věnuje vnitrostranickému dění, podílel se například na aktualizaci stanov či na debatě o reorganizaci strany. Ve veřejném prostoru se zajímá o financování sportu, regulaci hazardu či sociální a vzdělávací témata. V současné době se živí jako IT developer – vývojář a technický konzultant – a občas stále zabrousí do světa sportovních sázek, kterými se jako profesionální sportovní sázkař a později jako bookmaker živil dříve.",
        link: "https://forum.pirati.cz/viewtopic.php?p=860509#p860509",
        img: "lukas-hejduk.jpg",
      },
      {
        name: "Jakub Wolf",
        desc: "Jakub Wolf (10. 12. 1978) je od roku 2023 prvním místopředsedou pirátského místního sdružení Praha 7. Podílel se na volebním programu 2021 v oblasti zahraniční politika a školství, revizi dlouhodobého programu a programu pro komunální volby 2024 v oblasti kultury. Je členem resortních týmů Evropa-Zahraničí-Obrana a Kultura. Dříve pracoval jako redaktor a v marketingu, což ho přivedlo i do pirátského mediálního odboru. V letech 2021–2023 byl poradcem Jana Lipavského. Nyní na částečný úvazek pracuje v pirátském politickém Institutu Π, kde je administrátorem serveru, autorem článků a organizuje veřejné debaty",
        link: "https://forum.pirati.cz/viewtopic.php?p=859143#p859143",
        img: "jakub-wolf.jpg",
      },
      {
        name: "Markéta Monsportová",
        desc: "Markéta Monsportová (24. 6. 1972) kandidovala za Piráty v krajských volbách v roce 2016 a od roku 2018 je zastupitelkou Karlovarského kraje. Působila jako členka krajské komise pro kulturu a památkovou péči. V roce 2020 kandidovala do Senátu za obvod č. 3 a skončila na 4. místě s téměř 4000 hlasy. Kandidovala současně do krajského zastupitelstva a post zastupitelky obhájila z druhé pozice. Stala se tak uvolněnou zastupitelkou Karlovarského kraje s gescí pro cestovní ruch, lázeňství a kulturní a kreativní odvětví a předsedkyní Výboru pro národnostní menšiny.",
        link: "https://forum.pirati.cz/viewtopic.php?p=862445#p862445",
        img: "marketa-monsportova.jpg",
      },
      {
        name: "Mikuláš Ferjenčík",
        desc: "Mikuláš Ferjenčík (19. 3. 1987) vystudoval bakalářské studium na FHS UK, pracoval jako koordinátor dobrovolníků v protikorupční kampani Rekonstrukce státu, překládal a vedl kroužky zábavné logiky. Byl zastupitelem v Praze a v letech 2017–2021 poslancem. Nyní pracuje jako asistent předsedy poslaneckého klubu Pirátů Jakuba Michálka. V Poslanecké sněmovně se věnoval především problematice financí z pozice místopředsedy rozpočtového výboru. Prosadil výrazné zvýšení daňové slevy na poplatníka. Každý pracující díky tomu ušetřil 6 000 korun ročně, vyjednal zlepšení podmínek pro domácí vaření piva a prosadil kompenzační bonus pro dohodáře.",
        link: "https://forum.pirati.cz/viewtopic.php?p=864480#p864480",
        img: "mikulas-ferjencik.jpg",
      },
    ],
  },
];
