import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
// import { venues } from "config";
import { partyVenue2 } from "config";
import { partyVenue3 } from "config";


const Party = () => {
//  const venueItem=venues[0];
  const venueItem2=partyVenue2;
  const venueItem3=partyVenue3;
  return (
    <article className="w-full">
      <PageTitle>Preparty a Afterparty</PageTitle>

      <Helmet>
        <title>Preparty a Afterparty | {basics.appTitle}</title>
        <meta property="og:title" content="Preparty a Afterparty" />
      </Helmet>

      <div className="content-block">

        <h3>Preparty</h3>

        <p>
            Preparty se bude konat v pátek 8.řijna od 20:00 v klubu <a href="https://www.lafuente.cz/">LaFuente</a>.
            těšit se můžeš na elektronickou hudbu a pirátské DJS do ranních hodin. Lineup zveřejníme před akcí.
        </p>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem2.street}
          city={venueItem2.city}
          gps={venueItem2.gps}
          href={venueItem2.href}
          title_short={venueItem2.title_short}
        />
      </div>

        <h3>Afterparty</h3>

        <p>
Afterparty se bude konat v sobotu 9. žijna  od 20:00 ve studentském klubu <a href="https://www.facebook.com/masarka.club/">Masařka</a>,
ve stejné budově kde se odehraje zasedání CF, jen o patro níž. Těšit se můžete na pirátský jam session! Máš hudební nástroj?
Přibal ho na afterparty sebou!
        </p>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem3.street}
          city={venueItem3.city}
          gps={venueItem3.gps}
          href={venueItem3.href}
          title_short={venueItem3.title_short}
        />
      </div>
      </div>
    </article>
  );
};

export default Party;
